<template>
  <ValidationProvider
    v-slot="{ errors, required }"
    ref="provider"
    class="TextInput"
    :class="{
      'opacity-50': readonly,
      'has-icon': Boolean(hasIcon),
      before: hasIcon === 'before',
      after: hasIcon === 'after',
    }"
    tag="div"
    :rules="rules"
    :name="name"
    :vid="id"
  >
    <label v-if="label" class="TextInput__label" :for="id">
      <span>{{ label }}</span>
      <span>{{ required ? ' *' : '' }}</span>
    </label>
    <div class="relative mb-1">
      <slot name="icon-before" />
      <input
        v-if="type !== 'textarea' && type !== 'tel'"
        :id="id"
        v-model="innerValue"
        :name="name"
        :class="{
          'border-red-100': errors[0],
          'has-value': hasValue,
        }"
        :type="showPassword ? 'text' : type"
        :placeholder="placeholder"
        v-bind="$attrs"
        :autocomplete="autocomplete"
        :readonly="readonly"
      />
      <input
        v-else-if="type === 'tel'"
        :id="id"
        v-model="innerValue"
        maxlength="11"
        :name="name"
        :class="{
          'border-red-100': errors[0],
          'has-value': hasValue,
        }"
        :type="showPassword ? 'text' : type"
        :placeholder="placeholder"
        v-bind="$attrs"
        :autocomplete="autocomplete"
        :readonly="readonly"
        @keydown="preventNonNumeric"
      />
      <input
        v-else-if="type === 'date'"
        :id="id"
        v-model="innerValue"
        :name="name"
        :class="{
          'border-red-100': errors[0],
          'has-value': hasValue,
        }"
        type="date"
        :placeholder="placeholder"
        v-bind="$attrs"
        :autocomplete="autocomplete"
        :readonly="readonly"
        @keydown="preventNonNumeric"
      />
      <textarea
        v-else-if="type === 'textarea'"
        :id="id"
        v-model="innerValue"
        :name="name"
        :class="{
          'border-red-100': errors[0],
          'has-value': hasValue,
        }"
        :type="type"
        :placeholder="placeholder"
        v-bind="$attrs"
        rows="5"
      ></textarea>
      <button
        v-if="type === 'password'"
        aria-label="اظهر كلمة السر"
        type="button"
        name="show-password"
        class="showPassword"
        tabindex="-1"
        @click="showPassword = !showPassword"
      >
        <svg width="24" height="24" class="fill-current ml-2">
          <use xlink:href="@/assets/svg/symbol/svg/sprite.symbol.svg#show-password"></use>
        </svg>
      </button>
      <slot name="icon-after" />
    </div>
    <p v-if="footnote" class="TextInput__footnote">
      {{ footnote }}
    </p>
    <p v-if="errors[0]" class="TextInput__error">
      {{ errors[0] }}
    </p>

    <slot name="after" />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'TextInput',
  components: {
    ValidationProvider,
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    autocomplete: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    footnote: {
      type: String,
      default: '',
    },
    hasIcon: {
      type: String,
      default: null,
    },
  },
  data: vm => ({
    innerValue: vm.value || '',
    showPassword: false,
  }),
  computed: {
    hasValue() {
      return !!this.innerValue.length;
    },
  },
  watch: {
    innerValue(value) {
      this.$emit('input', value);
    },
    value(value) {
      if (value !== this.innerValue) {
        this.innerValue = value;
      }
    },
  },
  methods: {
    preventNonNumeric(e) {
      const copy = (e.ctrlKey || e.metaKey) && e.keyCode === 67;
      const cut = (e.ctrlKey || e.metaKey) && e.keyCode === 88;
      const paste = (e.ctrlKey || e.metaKey) && e.keyCode === 86;
      const selectAll = (e.ctrlKey || e.metaKey) && e.keyCode === 65;
      const regex = /\d|Backspace|Enter|ArrowLeft|ArrowRight/;
      if (!copy && !cut && !paste && !selectAll && !regex.test(e.key)) {
        e.preventDefault();
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
.TextInput {
  @apply relative appearance-none w-full;
  input,
  textarea {
    @apply bg-gray-100 p-3 w-full;
    position: relative;
    border-radius: 12px;
    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
  input {
    height: 50px;
    min-width: 350px;
  }

  &.has-icon {
    position: relative;
    &.before {
      input {
        padding-inline-start: 3.125rem;
      }
    }
    &.after {
      input {
        padding-inline-end: 3.125rem;
      }
    }
  }

  &__label {
    @apply block inset-0 w-full mb-2 leading-normal font-bold text-sm;
    user-select: none;
  }

  &__error {
    @apply block text-sm text-red-100;
  }

  &__footnote {
    @apply block text-sm;
  }

  input.has-value,
  input:focus,
  textarea.has-value,
  textarea:focus,
  button:focus {
    @apply outline-none;
  }

  input::placeholder {
    @apply text-sm text-black;
  }

  .showPassword {
    position: absolute;
    top: 45%;
    left: 12px;
    width: 31px;
    height: 16px;
    transform: translateY(-50%);
    z-index: 10;
  }

  input[type='number'] {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
</style>
