import { useMutation, useQuery } from 'villus';
import {
  AddItemDocument,
  ApplyCouponToCartDocument,
  AssignVehicleToCartDocument,
  BookCartServicesDocument,
  ClearCartDocument,
  CreateCartDocument,
  CustomerCartDocument,
  CustomerCartQuery,
  CustomerCartQueryVariables,
  GetNearestTimesDocument,
  PlaceOrderDocument,
  RemoveCouponFromCartDocument,
  RemoveItemDocument,
  SaveServiceBookingsDocument,
  SetBillingAddressDocument,
  SetPaymentMethodOnCartDocument,
  SetShippingLocationDocument,
} from '@/graphql/Cart';
import { inject, reactive, toRefs, watch, computed } from '@vue/composition-api';
import { MaybeReactive, Schedule, Unpacked } from '@/types/utils';
import { resolveProductPrice } from '@/utils/products';
import { toNonNullable } from '@/utils/collections';
import { CartAddressInput, CartItemInput, CustomerVehicle } from 'graphql-types.gen';
import { AUTH_USER } from '@/utils/provides';
import { useAlerts } from '@/features/alerts';
const { error: errorToast } = useAlerts();

export type MappedCartItem = ReturnType<typeof mapCartItem>;

type CartStateType = {
  cartId: string;
  serviceItems: ReturnType<typeof mapCartItem>[];
  addOnItems: ReturnType<typeof mapCartItem>[];
  total: number;
  subtotal: number;
  appliedCoupons?: string;
  promoCodeDiscount?: number;
  vehicle: Partial<CustomerVehicle>;
};

const cartState = reactive<CartStateType>({
  cartId: '',
  serviceItems: [],
  addOnItems: [],
  total: 0,
  subtotal: 0,
  appliedCoupons: '',
  promoCodeDiscount: 0,
  vehicle: {},
});

function mapCartState(cart: CustomerCartQuery['cart'] | null): typeof cartState {
  if (!cart) {
    return cartState;
  }

  return {
    cartId: cart.id,
    total: cart.prices?.grand_total?.value ?? 0,
    subtotal: cart.prices?.subtotal_including_tax?.value ?? 0,
    serviceItems: toNonNullable(cart.items)
      .filter(item => !item.product.isAddOn)
      .map(mapCartItem),
    addOnItems: toNonNullable(cart.items)
      .filter(item => item.product.isAddOn)
      .map(mapCartItem),
    appliedCoupons: cart.applied_coupons?.[0]?.code as string | undefined,
    promoCodeDiscount: (cart.prices?.discounts?.[0]?.amount?.value as number) || 0,
    vehicle: cart.vehicle as CustomerVehicle,
  };
}

function patchCartState(newState: Partial<typeof cartState>) {
  Object.keys(newState).forEach(key => {
    (cartState as any)[key] = (newState as any)[key];
  });
}

function usePrepareCart() {
  const { execute } = useMutation(CreateCartDocument);
  const { execute: executeCustomerCart } = useQuery({
    query: CustomerCartDocument,
    cachePolicy: 'network-only',
    fetchOnMount: false,
  });

  // Prepare cart state
  async function prepareCart() {
    if (cartState.cartId) {
      return;
    }

    // Fetch customer cart
    const { data: cartData } = await executeCustomerCart();

    if (cartData?.cart) {
      patchCartState(mapCartState(cartData.cart));
      return;
    }

    /**
     * Execute create empty cart
     * if not have customer cart
     */
    const { data, error } = await execute();

    if (error) {
      throw new Error(error.message);
    }

    if (data.response) {
      patchCartState({ cartId: data.response || '' });
    }
  }

  return {
    prepareCart,
  };
}

export function useCart(variables?: MaybeReactive<CustomerCartQueryVariables>) {
  // mapped variables with defaults
  const mappedVariables = computed(() => {
    const vars = variables && 'value' in variables ? variables?.value : variables;

    return {
      storeId: null,
      storeIdAvailable: false,
      ...vars,
    };
  });

  const { data, execute, isFetching } = useQuery({
    query: CustomerCartDocument,
    variables: mappedVariables,
    cachePolicy: 'network-only',
    fetchOnMount: false,
  });

  watch(data, (value: any) => {
    patchCartState(mapCartState(value?.cart));
  });

  // Refetch cart if state is empty
  if (!cartState.serviceItems.length) {
    execute();
  }

  return {
    ...toRefs(cartState),
    isFetchingCart: isFetching,
    refetchCart: execute,
  };
}

export function useGetNearestTimes() {
  const { execute, isFetching } = useQuery({ query: GetNearestTimesDocument });

  async function getNearestTimes(storeLocatorId: number) {
    const { data, error } = await execute({ variables: { cartId: cartState.cartId, storeLocatorId } });

    if (error) {
      throw new Error(error.message);
    }

    return data?.nearestTimes;
  }

  return {
    getNearestTimes,
    isFetchingTimes: isFetching,
  };
}

export function useAddCartItem() {
  const { execute, isFetching: isAdding } = useMutation(AddItemDocument);

  async function addItem(cartItems: CartItemInput[]) {
    try {
      const { data, error } = await execute({
        cartId: cartState.cartId,
        cartItems,
      });

      if (data?.response) {
        patchCartState(mapCartState(data.response.cart));
      }

      if (error) {
        errorToast(error.message);
        throw new Error(error.message);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      throw err;
    }
  }

  return {
    addItem,
    isAdding,
  };
}

export function useRemoveCartItem(sku?: string) {
  const { execute, isFetching: isRemoving } = useMutation(RemoveItemDocument);
  async function removeItem(productSku?: string) {
    const item =
      cartState.serviceItems.find(i => i.sku === (sku ?? productSku)) ||
      cartState.addOnItems.find(i => i.sku === (sku ?? productSku));

    const result = await execute({
      input: {
        cart_id: cartState.cartId,
        cart_item_uid: item?.id,
      },
    });

    if (result.data.response?.cart) {
      patchCartState(mapCartState(result.data.response.cart));
    }

    if (result.error) {
      throw new Error(result.error.message);
    }
  }

  return { removeItem, isRemoving };
}

export function useRemoveCouponFromCart() {
  const { execute, isFetching: isRemoving } = useMutation(RemoveCouponFromCartDocument);
  async function removeCoupon(storeId: number) {
    const result = await execute({
      input: {
        cart_id: cartState.cartId,
      },
      storeId,
    });

    if (result.data.response?.cart) {
      patchCartState(mapCartState(result.data.response.cart));
    }

    if (result.error) {
      throw new Error(result.error.message);
    }
  }

  return { isRemoving, removeCoupon };
}

export function useApplyCouponToCart() {
  const { execute, isFetching: isApplying } = useMutation(ApplyCouponToCartDocument);
  async function applyCoupon(couponCode: string, storeId: number) {
    const result = await execute({
      input: {
        cart_id: cartState.cartId,
        coupon_code: couponCode,
      },
      storeId,
    });

    if (result.data.response?.cart) {
      patchCartState(mapCartState(result.data.response.cart));
    }

    if (result.error) {
      throw new Error(result.error.message);
    }
  }

  return { isApplying, applyCoupon };
}

export function useSaveBookings() {
  const { execute, isFetching: isSaving } = useMutation(SaveServiceBookingsDocument);

  const user = inject(AUTH_USER);

  async function saveBookings(servicesSchedules: Array<Schedule>) {
    if (!user?.value) throw new Error('You must log in to do this action');

    const mappedSchedules = servicesSchedules.map(schedule => {
      return {
        cart_item_uid: schedule.cartItemId,
        bookable_service_uid: schedule.uid,
        booking_day: schedule.day,
        booking_time: schedule.time,
      };
    });
    const result = await execute({
      cartId: cartState.cartId,
      bookableCartServices: mappedSchedules,
      adminEmail: user?.value?.email || '',
    });

    if (result.data.response?.cart) {
      patchCartState(mapCartState(result.data.response.cart));
    }

    if (result.error) {
      throw new Error(result.error.message);
    }
  }

  return { saveBookings, isSaving };
}

export function useBookCartServices() {
  const { execute, isFetching: isSaving } = useMutation(BookCartServicesDocument);
  const user = inject(AUTH_USER);

  async function bookServices(storeId: number, day: string, startTime: string) {
    if (!user?.value) throw new Error('You must log in to do this action');

    const result = await execute({
      input: {
        cart_id: cartState.cartId,
        store_locator_id: storeId,
        day,
        start_time: startTime,
      },
      adminEmail: user?.value.email || '',
    });

    if (result.error) {
      throw new Error(result.error.message);
    }
  }

  return { bookServices, isSaving };
}

export function usePlaceOrder() {
  const { execute, isFetching: isPlacingOrder } = useMutation(PlaceOrderDocument);

  async function placeOrder(cardId: string = '') {
    try {
      const { data, error } = await execute({
        cartId: cardId || cartState.cartId,
      });

      if (error) {
        throw new Error(error.message);
      }

      return {
        order: data.response?.order,
        error,
      };
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      throw err;
    }
  }

  return {
    placeOrder,
    isPlacingOrder,
  };
}

export function useSetPaymentMethod() {
  const { execute, isFetching: isSettingPaymentMethod } = useMutation(SetPaymentMethodOnCartDocument);

  async function setPaymentMethod(cartId: string = '', code = 'cashondelivery') {
    const { data } = await execute({
      input: {
        cart_id: cartId || cartState.cartId,
        payment_method: {
          // Set default as cash on delivery
          // As all payments will be at the branch
          code,
        },
      },
    });
    return data.response;
  }

  return {
    isSettingPaymentMethod,
    setPaymentMethod,
  };
}

export function useSetBillingAddress() {
  const { execute } = useMutation(SetBillingAddressDocument);

  async function setBillingAddress(billingAddress: CartAddressInput, storeId: number) {
    const billing_address = {
      address: billingAddress,
      use_for_shipping: true,
    };

    const { data, error } = await execute({
      input: {
        cart_id: cartState.cartId,
        billing_address,
      },
      storeId,
    });

    if (error) {
      throw new Error(error.message);
    }

    if (data.response?.cart) {
      patchCartState(mapCartState(data.response?.cart));
    }
  }

  return {
    setBillingAddress,
  };
}

export function useSetShippingLocation() {
  const { execute } = useMutation(SetShippingLocationDocument);

  async function setShippingLocation(storeId: number) {
    const { data, error } = await execute({
      input: {
        cart_id: cartState.cartId,
        store_id: storeId,
      },
      storeId,
    });

    if (error) {
      throw new Error(error.message);
    }

    if (data.response?.cart) {
      patchCartState(mapCartState(data.response?.cart));
    }
  }

  return {
    setShippingLocation,
  };
}

export function useAssignVehicleToCart() {
  const { execute, isFetching } = useMutation(AssignVehicleToCartDocument);
  const { prepareCart } = usePrepareCart();

  async function assignVehicle(vehicleUid: string) {
    await prepareCart();

    const { data, error } = await execute({
      cartId: cartState.cartId,
      vehicleUid,
    });

    if (error) {
      throw new Error(error.message);
    }

    if (data.response?.cart) {
      patchCartState(mapCartState(data.response?.cart));
    }
  }

  return {
    assignVehicle,
    isAssigningVehicle: isFetching,
  };
}

export function useClearCart() {
  const { execute } = useMutation(ClearCartDocument);
  const { prepareCart } = usePrepareCart();

  async function clearCart() {
    await prepareCart();

    const { data, error } = await execute({
      cartId: cartState.cartId,
    });

    if (error) {
      throw new Error(error.message);
    }

    if (data.response) {
      patchCartState(mapCartState(data.response));
    }
  }

  return {
    clearCart,
  };
}

export function mapCartItem(apiItem: NonNullable<Unpacked<NonNullable<CustomerCartQuery['cart']>['items']>>) {
  const baseProductPrice = resolveProductPrice(apiItem.product);
  const unitPrice = apiItem.prices?.price.value || baseProductPrice;
  const oldPrice = apiItem.product.price_range.maximum_price?.regular_price.value ?? unitPrice;
  const discountPercentage = unitPrice / oldPrice !== 1 ? Math.round(((oldPrice - unitPrice) / oldPrice) * 100) : 0;
  const item = {
    id: apiItem.uid,
    name: apiItem.product.name,
    unitPrice,
    oldPrice,
    sku: apiItem.product.sku,
    quantity: apiItem?.quantity,
    averageDuration: apiItem.product.averageDuration || 0,
    isPendingRemoval: false,
    discountPercentage,
    storeSlotDuration: apiItem.product.bookableService?.storeSlotDuration,
    category: apiItem.product.categories?.[apiItem.product.categories.length - 1],
    isAddOn: apiItem.product.isAddOn,
    uid: apiItem?.product?.bookableService?.uid,
  };

  return item;
}
