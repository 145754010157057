import Vue from 'vue';
import VueCompositionAPI, { ref } from '@vue/composition-api';

Vue.use(VueCompositionAPI);
interface AlertOptions {
  id: number;
  title: string;
  subtitle?: string;
  type: 'success' | 'danger' | 'info' | 'warning';
  formAlert: boolean;
}

const alerts = ref<AlertOptions[]>([]);
let ID_COUNTER = 0;

export function useAlerts() {
  function notify(title: string, subtitle: string, type: AlertOptions['type'], formAlert: boolean) {
    const id = ID_COUNTER++;
    alerts.value.push({
      id,
      title,
      subtitle,
      type,
      formAlert,
    });
    // time to hide alert after (8 seconds * 1000 milliseconds )
    setTimeout(() => {
      dismiss(id);
    }, 4 * 1000);
  }

  function success(title: string, formAlert?: boolean, subtitle?: string) {
    notify(title, subtitle ?? '', 'success', formAlert || false);
  }
  function error(title: string, formAlert?: boolean, subtitle?: string) {
    notify(title, subtitle ?? '', 'danger', formAlert || false);
  }
  function warn(title: string, formAlert?: boolean, subtitle?: string) {
    notify(title, subtitle ?? '', 'warning', formAlert || false);
  }
  function info(title: string, formAlert?: boolean, subtitle?: string) {
    notify(title, subtitle ?? '', 'info', formAlert || false);
  }

  function dismiss(id: number) {
    const idx = alerts.value.findIndex(i => i.id === id);
    if (idx === -1) {
      return;
    }

    alerts.value.splice(idx, 1);
  }

  function dismissAll() {
    alerts.value = [];
  }

  return {
    alerts,
    success,
    info,
    error,
    warn,
    dismiss,
    dismissAll,
  };
}
