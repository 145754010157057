import useCookies from './cookies';
import { CombinedError, useMutation, useQuery } from 'villus';
import {
  LoginDocument,
  IdentityDocument,
  IdentityQuery,
  RevokeTokenDocument,
  RequestAdminPasswordResetDocument,
  ResetAdminPasswordDocument,
} from '@/graphql/Auth';
import { ref, computed } from '@vue/composition-api';
import type { ClientPlugin } from 'villus';
import { DocumentNode, OperationDefinitionNode } from 'graphql';

let PENDING_IDENTIFICATION: Promise<IdentityQuery['admin'] | undefined> | undefined;
export const user = ref<IdentityQuery['admin'] | null | undefined>(undefined);
const authToken = ref('');

export function setUser(data: typeof user['value']) {
  data ? window.localStorage.setItem('user', JSON.stringify(data)) : window.localStorage.removeItem('user');
  user.value = data;
}

function setToken(token: string) {
  authToken.value = token;
}

export async function getUser() {
  if (PENDING_IDENTIFICATION) {
    await PENDING_IDENTIFICATION;
  }

  const { cookies } = useCookies();

  if (cookies.auth && window.localStorage.getItem('user')) {
    return JSON.parse(window.localStorage.getItem('user') as string);
  }

  if (cookies.auth && user.value) {
    return user.value;
  }

  return null;
}

async function authenticateUser(token: string) {
  PENDING_IDENTIFICATION = useIdentity().identify(token);
  await PENDING_IDENTIFICATION;
}

export function useAuth() {
  const { cookies } = useCookies();

  if (!cookies.auth) {
    setUser(undefined);
  }

  if (cookies.auth) {
    authenticateUser(cookies.auth);
  }

  const isLoggedIn = computed(() => {
    return !!user.value;
  });

  return {
    isLoggedIn,
    user,
    setUser,
  };
}

export function useLogin() {
  const { execute, isFetching } = useMutation(LoginDocument);
  const { setCookie } = useCookies();
  const { identify } = useIdentity();

  async function login(input: { username: string; password: string }) {
    try {
      const { data, error } = await execute(input);

      if (error) {
        throw new Error(error.message);
      }

      if (!data?.response?.token) {
        throw new Error('No token was generated');
      }

      setCookie('refreshToken', data?.response?.refresh_token as string, {
        expires: new Date(Date.now() + 1000 * 60 * 60 * 24),
      });
      setCookie('auth', data?.response?.token as string, {
        expires: new Date(Date.now() + 1000 * 60 * 60),
      });

      const user = await identify(data?.response?.token ?? '');

      if (!user) {
        throw new Error('No user was authenticated with token');
      }

      setUser(user);
      return user;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      throw err;
    }
  }

  return {
    login,
    isLoggingIn: isFetching,
  };
}

function useIdentity() {
  const { setCookie, removeCookie } = useCookies();
  const { execute } = useQuery({
    query: IdentityDocument,
    cachePolicy: 'network-only',
    fetchOnMount: false,
  });

  async function identify(token?: string) {
    try {
      // Only set the token if provided
      if (token) {
        setCookie('auth', token, {
          expires: new Date(Date.now() + 1000 * 60 * 60),
        });
        setToken(token);
      }

      const { data, error } = await execute();

      if (error) {
        throw new Error(error.message);
      }

      setUser(data?.admin);
      return data?.admin;
    } catch (err) {
      removeCookie('auth');
      // Token expired or invalid, no need to report the error
      if (/The current customer isn't authorized/.test((err as CombinedError).message)) {
        return;
      }
      // eslint-disable-next-line
      console.log(err);
    }
  }

  return {
    identify,
  };
}

export function useRequestPasswordChange() {
  const { execute, isFetching } = useMutation(RequestAdminPasswordResetDocument);

  async function requestChange(email: string, username: string) {
    try {
      const { data, error } = await execute({ email, username });

      if (error) {
        throw new Error(error.message);
      }

      return data.response;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    requestChange,
    isRequesting: isFetching,
  };
}

export function useResetPassword() {
  const { execute, isFetching } = useMutation(ResetAdminPasswordDocument);

  async function resetPassword(username: string, resetPasswordToken: string, newPassword: string) {
    try {
      const { data, error } = await execute({ username, resetPasswordToken, newPassword });

      if (error) {
        throw new Error(error.message);
      }

      return data.response;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    resetPassword,
    isResetting: isFetching,
  };
}

/**
 * villus client auth plugin
 */
export function authPlugin(): ClientPlugin {
  return async function authPlugin({ operation, opContext }) {
    const user = await getUser();
    // Offline roles are Branch Managers and Branch Employees
    const isOfflineRole = Boolean(user?.role?.is_single_branch_role);

    (opContext.headers as Record<string, any>).store = 'ar_EG';
    (opContext.headers as Record<string, any>).sourcecode = 'CA';
    (opContext.headers as Record<string, any>).Clientsource = 'bsonline';

    // Queries and mutations that should be sent
    // with the customer token instead of the admin token
    const customerRequests = [
      'CreateAddress',
      'GetCustomerByToken',
      'CreateCart',
      'CustomerCart',
      'AddItem',
      'RemoveItem',
      'RemoveCouponFromCart',
      'ApplyCouponToCart',
      'SaveServiceBookings',
      'PlaceOrder',
      'SetPaymentMethodOnCart',
      'SetBillingAddress',
      'AddVehicleToCustomer',
      'EditCustomerVehicle',
      'DeleteVehicle',
      'AssignVehicleToCart',
      'UpdateCustomerPhoneNumber',
      'ClearCart',
      'SetShippingLocation',
      'GetNearestTimes',
      'BookCartServices',
      'GetAvailableTimes',
      'InitiateJumstart',
      'InitiateVanService',
      'ReservationCart',
      'ReservationAddToCart',
      'ReservationRemoveFromCart',
      'ReservationUpdateCart',
      'BookJumpStart',
      'BookVan',
    ];
    const queryName = ((operation.query as DocumentNode).definitions[0] as OperationDefinitionNode).name?.value;

    if (authToken.value && !customerRequests.includes(queryName as string)) {
      (opContext.headers as Record<string, any>).authorization = `Bearer ${authToken.value}`;
      return;
    }

    const { cookies } = useCookies();
    if (cookies.customerToken && customerRequests.includes(queryName as string)) {
      // Send this header only if PlaceOrder mutation
      if (queryName?.includes('PlaceOrder')) {
        (opContext.headers as Record<string, any>).clientsource = isOfflineRole ? 'bsoffline' : 'bsonline';
      }
      (opContext.headers as Record<string, any>).authorization = `Bearer ${cookies.customerToken}`;
    }
  };
}

export function useLogout() {
  const { execute: revokeToken } = useMutation(RevokeTokenDocument);
  const { removeCookie } = useCookies();

  async function logout() {
    await revokeToken();

    removeCookie('auth');
    removeCookie('refreshToken');
    user.value = undefined;
    authToken.value = '';
  }

  return {
    logout,
  };
}
