import {
  AssignJsmRiderInput,
  CartItemUpdateInput,
  CustomerVehicle,
  InitiateJumpStartInput,
  AddToJumpStartOrderInput,
  AddToVanServiceOrderInput,
} from 'graphql-types.gen';
import { mapCartItem, MappedCartItem } from './cart';
import { inject, reactive, ref, Ref, toRefs, watch } from '@vue/composition-api';
import { useMutation, useQuery } from 'villus';

import {
  BookJumpStartDocument,
  InitiateJumstartDocument,
  AddToJumpStartOrderDocument,
  RemoveFromJumpStartOrderDocument,
} from '@/graphql/Jumpstart';
import {
  AssignVsRiderDocument,
  BookVanDocument,
  InitiateVanServiceDocument,
  RemoveFromVanServiceOrderDocument,
  AddToVanServiceOrderDocument,
} from '@/graphql/Vans';
import useCookies from './cookies';
import { AssignVehicleToCartDocument, CustomerCartQuery } from '@/graphql/Cart';
import { toNonNullable } from '@/utils/collections';
import {
  ReservationAddToCartDocument,
  ReservationCartDocument,
  ReservationRemoveFromCartDocument,
  ReservationUpdateCartDocument,
} from '@/graphql/Reservation';
import { useAlerts } from '@/features/alerts';
import { AUTH_USER } from '@/utils/provides';
import { AssignJsmRiderDocument } from '@/graphql/Riders';

type ReservationCartStateType = {
  id: string;
  total: number;
  subtotal: number;
  items: MappedCartItem[];
  products: MappedCartItem[];
  vehicle: Partial<CustomerVehicle>;
  type: ReservationeEnum;
  loading?: boolean;
};

export enum ReservationeEnum {
  Van = 'van-service',
  Jumpstart = 'jump-start-me',
}

type InitiateInputType = {
  input: InitiateJumpStartInput;
  vehicleUid: string;
};

const reservationCartState = reactive<ReservationCartStateType>({
  id: '',
  items: [],
  products: [],
  total: 0,
  subtotal: 0,
  vehicle: {},
  type: ReservationeEnum.Jumpstart,
  loading: false,
});

/**
 * Retrieves the reactive state of the reservation cart as individual refs.
 *
 * This function converts the reactive `reservationCartState` object into a
 * set of refs, making each property of the state accessible as a separate
 * reactive reference. This allows components to reactively use and watch
 * these individual properties.
 **/
export function useReservationCartState() {
  return {
    ...toRefs(reservationCartState),
  };
}

/**
 * Sets the reservation type in the `reservationCartState` based on the value stored in localStorage under the 'service' key.
 *
 * This function retrieves the 'service' item from localStorage and, if it exists, assigns it to the `reservationCartState.type`.
 * The value is cast to the `ReservationeEnum` type.
 *
 */
export const useSetReservarionType = () => {
  const service = localStorage.getItem('service');
  if (service) {
    reservationCartState.type = service as ReservationeEnum;
  }
};

/**
 * Represents a service with a label, value, and icon.
 * @typedef {Object} Service
 * @property {string} label - The label for the service.
 * @property {ReservationeEnum} value - The enum value representing the type of service.
 * @property {string} icon - The icon associated with the service.
 */
export const useReservationServices = () => {
  const services = [
    {
      label: 'حجز انقاذ بطارية',
      value: ReservationeEnum.Jumpstart,
      icon: {
        name: 'technical-support',
        width: 24,
        height: 24,
      },
    },
    {
      label: 'حجز خدمات الفان',
      value: ReservationeEnum.Van,
      icon: {
        name: 'van',
        width: 30,
        height: 20,
      },
    },
  ];

  return { services };
};

/**
 * A composable function to handle the reservation process for different service types.
 *
 * This function manages the state and execution of reservation actions, such as initiating a jumpstart or van service,
 * assigning a vehicle to the cart, and handling the state of the reservation process.
 *
 * @returns {Object} An object containing the reservation initiation function and loading state.
 */
export const useReservation = () => {
  const loading = ref(false);
  // Mutations
  const { execute: assignVehicle } = useMutation(AssignVehicleToCartDocument);
  const { execute: executeJumpstart } = useMutation(InitiateJumstartDocument);
  const { execute: executeVan } = useMutation(InitiateVanServiceDocument);
  const { error: toastError } = useAlerts();

  // Persist keys
  const { setCookie } = useCookies();

  const initiate = async (inputs: InitiateInputType, type: ReservationeEnum) => {
    try {
      loading.value = true;

      const currentExecute = type === ReservationeEnum.Jumpstart ? executeJumpstart : executeVan;
      const { data, error } = await currentExecute(inputs);

      if (error) {
        toastError(error?.message);
        throw new Error(error.message);
      }

      if (data?.response?.cart) {
        const cartId = data?.response?.cart?.id;

        setCookie('reservationCartId', cartId);
        localStorage.setItem('service', type);
        reservationCartState.type = type;

        const cart = await assignVehicleAction(cartId, inputs?.vehicleUid);

        patchReservationCartState(mapReservationCartState(cart));
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      throw err;
    } finally {
      loading.value = false;
    }
  };

  // Assign vehicle to jumpstart service cart
  async function assignVehicleAction(cartId: string, vehicleUid: string) {
    try {
      const { data, error } = await assignVehicle({
        cartId,
        vehicleUid,
      });

      if (error) {
        throw new Error(error.message);
      }

      return data?.response?.cart;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);

      throw err;
    }
  }

  return {
    initiate,
    loading,
  };
};

/**
 * Patches the `reservationCartState` object with new values.
 *
 * This function updates the properties of `reservationCartState` with the values provided in `newState`.
 * Only the properties present in `newState` will be updated.
 */
function patchReservationCartState(newState: Partial<typeof reservationCartState>) {
  Object.keys(newState).forEach(key => {
    (reservationCartState as any)[key] = (newState as any)[key];
  });
}

/**
 * Maps a customer cart to a reservation cart state object.
 *
 * This function transforms a `CustomerCartQuery['cart']` object into a format compatible with the reservation cart state.
 * If the provided cart is null, the function returns the current `reservationCartState`.
 *
 * @param {CustomerCartQuery['cart'] | null} cart - The cart object from the customer query. If null, returns the current `reservationCartState`.
 *
 * @returns {typeof reservationCartState} - The mapped reservation cart state object.
 */
function mapReservationCartState(cart: CustomerCartQuery['cart'] | null) {
  if (!cart) {
    return reservationCartState;
  }

  const items = toNonNullable(cart.items).map?.(mapCartItem);

  return {
    id: cart.id,
    total: cart.prices?.grand_total?.value ?? 0,
    subtotal: cart.prices?.subtotal_including_tax?.value ?? 0,
    items,
    // Products without jumpstart or van service
    products: items?.filter(item => !Object.values(ReservationeEnum).includes(item?.sku as ReservationeEnum)),
    vehicle: cart.vehicle as CustomerVehicle,
  };
}

/**
 * Custom hook for managing the reservation cart state.
 *
 * This hook retrieves the reservation cart ID from cookies and uses it to fetch
 * the reservation cart data from a GraphQL query. It updates the reservation cart
 * state based on the fetched data and provides a reactive reference to the cart
 * state and the fetching status.
 */
export function useReservationCart() {
  const { cookies } = useCookies();
  const reservationCartId = cookies?.reservationCartId;

  if (reservationCartId) {
    reservationCartState.id = reservationCartId;
  }

  const { data, isFetching } = useQuery({
    query: ReservationCartDocument,
    variables: { cartId: reservationCartState.id },
    cachePolicy: 'network-only',
    fetchOnMount: true,
  });

  watch(data, (value: any) => {
    patchReservationCartState(mapReservationCartState(value?.cart));
  });

  return {
    ...toRefs(reservationCartState),
    isFetching,
  };
}

/**
 * A custom hook that provides functionality to add an item to the reservation cart.
 * It uses a GraphQL mutation to add the item to the cart and handles success and error alerts.
 */
export function useAddReservationCartItem() {
  const { execute } = useMutation(ReservationAddToCartDocument);
  const { removeItem } = useRemoveReservationCartItem();
  const { error, success } = useAlerts();

  /**
   * Adds an item to the reservation cart by its SKU.
   **/
  async function addItem(sku: string) {
    try {
      reservationCartState.loading = true;

      // Avoid adding exists product in cart
      const product = reservationCartState.products?.find(product => product?.sku === sku);
      if (product) return;

      // Remove item if add new one
      if (reservationCartState?.products?.length && reservationCartState.type === ReservationeEnum.Jumpstart) {
        const product = reservationCartState?.products[0];
        if (product?.id) {
          await removeItem(product?.id);
        }
      }

      const { data, error } = await execute({
        input: {
          cart_id: reservationCartState.id,
          cart_items: [
            {
              data: { quantity: 1, sku },
            },
          ],
        },
      });

      if (error) {
        throw new Error(error.message);
      }

      if (data?.response) {
        patchReservationCartState(mapReservationCartState(data?.response?.cart));
      }

      success('تم اضافة المنتج بنجاح');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      error(String(err));
      throw err;
    } finally {
      reservationCartState.loading = false;
    }
  }

  return {
    addItem,
  };
}

/**
 * Composable function to handle the removal of an item from the reservation cart.
 * It provides a method to remove an item by its ID, updates the cart state, and handles
 * loading and alert notifications.
 *
 * @returns {Object} An object containing the `removeItem` function.
 */
export function useRemoveReservationCartItem() {
  const { execute } = useMutation(ReservationRemoveFromCartDocument);
  const { error, success } = useAlerts();

  /**
   * Removes an item from the reservation cart by its ID.
   * Sets loading state to true, performs the mutation to remove the item, updates
   * the cart state, and handles success and error notifications.
   */
  async function removeItem(id: string) {
    try {
      reservationCartState.loading = true;

      const { data, error } = await execute({
        input: {
          cart_id: reservationCartState.id,
          cart_item_uid: id,
        },
      });

      if (error) {
        throw new Error(error.message);
      }

      if (data?.response) {
        patchReservationCartState(mapReservationCartState(data?.response?.cart));
      }

      success('تم حذف المنتج بنجاح');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      error(String(err));
      throw err;
    } finally {
      reservationCartState.loading = false;
    }
  }

  return {
    removeItem,
  };
}

/**
 * Composable function to handle the update of items in the reservation cart.
 * Provides a method to update cart items and handles loading state and alert notifications.
 *
 */
export function useUpdateReservationCartItem() {
  const { execute } = useMutation(ReservationUpdateCartDocument);
  const { error, success } = useAlerts();

  /**
   * Updates items in the reservation cart.
   * Sets loading state to true, performs the mutation to update the cart items,
   * updates the cart state, and handles success and error notifications.
   *
   */
  async function updateItem(cartItems: CartItemUpdateInput[]) {
    try {
      reservationCartState.loading = true;

      const { data, error } = await execute({
        input: {
          cart_id: reservationCartState.id,
          cart_items: cartItems,
        },
      });

      if (error) {
        throw new Error(error.message);
      }

      if (data?.response) {
        patchReservationCartState(mapReservationCartState(data?.response?.cart));
      }

      success('تم تعديل المنتج بنجاح');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      error(String(err));
      throw err;
    } finally {
      reservationCartState.loading = false;
    }
  }

  return {
    updateItem,
  };
}

/**
 * Provides functionality for booking a reservation based on the reservation type.
 *
 * This function uses Vue's `inject` to retrieve the current user and provides a `mutate` function
 * to perform booking operations based on the reservation type. It uses GraphQL mutations
 * to book either a Jumpstart or a Van.
 */
export function useBookReservation() {
  // Admin value
  const user = inject(AUTH_USER);

  // Define mutation functions for booking Jumpstart and Van
  const { execute: executeJumpstart } = useMutation(BookJumpStartDocument);
  const { execute: executeVan } = useMutation(BookVanDocument);

  /**
   * Performs the booking operation based on the reservation type.
   */
  async function mutate(cartId: string) {
    try {
      if (reservationCartState.type === ReservationeEnum.Jumpstart) {
        await executeJumpstart({ cartId, adminEmail: user?.value?.email });
      }

      if (reservationCartState.type === ReservationeEnum.Van) {
        await executeVan({ cartId, adminEmail: user?.value?.email });
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      throw err;
    }
  }

  return {
    mutate,
  };
}

/**
 * A custom hook to assign a rider based on the reservation type.
 */
export function useAssginReservationRider() {
  const isLoading = ref(false);

  const { execute: executeJsm } = useMutation(AssignJsmRiderDocument);
  const { execute: executeVs } = useMutation(AssignVsRiderDocument);

  /**
   * Executes the mutation based on the reservation type.
   */
  async function mutate(input: AssignJsmRiderInput, type: ReservationeEnum) {
    try {
      isLoading.value = true;

      const executeMutation = type === ReservationeEnum.Jumpstart ? executeJsm : executeVs;
      const { data, error } = await executeMutation({ input });

      if (error) {
        throw new Error(error?.message);
      }

      return data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      throw err;
    } finally {
      isLoading.value = false;
    }
  }

  return { mutate, isLoading };
}

/**
 * Custom hook for adding an item to a Jumpstart or Van service order.
 *
 * @function useAddReservationOrder
 * @returns {Object} The hook's return object containing the addItem function and isAdding state.
 */
export function useAddReservationOrder(type: Ref<ReservationeEnum>) {
  const { execute: executeJumpstart, isFetching: isAddingJumpstart } = useMutation(AddToJumpStartOrderDocument);
  const { execute: executeVan, isFetching: isAddingVan } = useMutation(AddToVanServiceOrderDocument);
  const { success, error: errorToast } = useAlerts();

  async function addJumpstartItem(sku: string, orderNumber: string) {
    const input: AddToJumpStartOrderInput = {
      sku,
      orderNumber,
    };

    try {
      const { error } = await executeJumpstart({ input });

      if (error) {
        errorToast(error.message);
        throw new Error(error.message);
      }

      success('تم إضافه المنتج بنجاح.');
    } catch (err) {
      console.error(err);
    }
  }

  async function addVanItem(sku: string, orderNumber: string, isAdjust?: boolean) {
    const input: AddToVanServiceOrderInput = {
      items: [{ productSku: sku, qty: 1 }],
      orderNumber,
    };

    try {
      const { error } = await executeVan({ input });

      if (error) {
        errorToast(error.message);
        throw new Error(error.message);
      }

      success(isAdjust ? 'تم تعديل المنتج بنجاح.' : 'تم إضافه المنتج بنجاح.');
    } catch (err) {
      console.error(err);
    }
  }

  return {
    addItem: type.value === ReservationeEnum.Jumpstart ? addJumpstartItem : addVanItem,
    isAdding: type.value === ReservationeEnum.Jumpstart ? isAddingJumpstart : isAddingVan,
  };
}

/**
 * Custom hook for removing an item from a Jumpstart or Van service order.
 *
 * @function useRemoveFromReservationOrder
 * @returns {Object} The hook's return object containing the removeItem function and isRemoving state.
 */
export function useRemoveFromReservationOrder(type: Ref<ReservationeEnum>) {
  const currentExecute =
    type.value === ReservationeEnum.Jumpstart ? RemoveFromJumpStartOrderDocument : RemoveFromVanServiceOrderDocument;
  const { execute, isFetching: isRemoving } = useMutation(currentExecute);
  const { success, error: errorToast } = useAlerts();

  async function removeItem(id: string, orderNumber: string, qty: number, isAdjust?: boolean) {
    try {
      const { error } = await execute({
        input: {
          items: [
            {
              itemId: id,
              qty,
            },
          ],
          orderNumber,
        },
      });

      if (error) {
        errorToast(error.message);
        throw new Error(error.message);
      }

      success(isAdjust ? 'تم تعديل المنتج بنجاح.' : `تم حذف المنتج  بنجاح`);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  return {
    removeItem,
    isRemoving,
  };
}
