import { render, staticRenderFns } from "./Customers.vue?vue&type=template&id=07d0a29a&scoped=true&"
import script from "./Customers.vue?vue&type=script&lang=ts&"
export * from "./Customers.vue?vue&type=script&lang=ts&"
import style0 from "./Customers.vue?vue&type=style&index=0&id=07d0a29a&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07d0a29a",
  null
  
)

export default component.exports