import { toNonNullable } from '@/utils/collections';
import { ref, Ref, watch } from '@vue/composition-api';
import { Admin, SearchResultPageInfo } from 'graphql-types.gen';
import { MaybeReactive, useQuery } from 'villus';
import { VansDocument, VansQueryVariables } from '@/graphql/Vans';
import { mapActiveBookingOrder } from '@/features/riders';

/*
 * Custom hook for fetching and managing van data.
 */
export function useVans(variables?: MaybeReactive<VansQueryVariables>) {
  const vans: Ref = ref([]);
  const totalCount: Ref<number> = ref(0);
  const pageInfo: Ref<SearchResultPageInfo> = ref({});

  const { data, isFetching, execute } = useQuery({
    query: VansDocument,
    variables,
    cachePolicy: 'network-only',
  });

  // Watch for changes in the data and update the reactive references
  watch(data, value => {
    vans.value = toNonNullable(value?.admins?.items as Admin[])?.map(mapVan);
    totalCount.value = value?.admins?.total_count as number;
    pageInfo.value = value?.admins?.page_info as SearchResultPageInfo;
  });

  return {
    vans,
    totalCount,
    pageInfo,
    isFetchingVans: isFetching,
    fetchVans: execute,
  };
}

/**
 * Maps a van service request from an `Admin` object to a more usable format.
 *
 * @param {Admin} apiVan - The `Admin` object containing van service request data.
 * @returns {Object} A mapped object containing the van's full name, number of requests, status, and active booking order.
 * @returns {string} return.fullName - The full name of the van service provider.
 * @returns {number|undefined} return.requestsNo - The total number of van service requests.
 * @returns {string} return.status - The status of the van service requests in lowercase. Returns an empty string if not available.
 * @returns {Object|null} return.booking - The first active booking order, or `null` if none exist.
 */
function mapVan(apiVan: Admin) {
  const serviceRequests = toNonNullable(apiVan?.vanServiceRequests?.items);
  const activeBookings = serviceRequests?.length ? serviceRequests?.map(mapActiveBookingOrder) : [];

  return {
    fullName: `${apiVan.firstname} ${apiVan.lastname}`,
    requestsNo: apiVan?.vanServiceRequests?.total_count,
    completedRequestsNo: apiVan?.vanServiceRequests?.total_completed_count,
    status: apiVan?.vanServiceRequests?.status?.toLowerCase() || '',
    booking: activeBookings?.length ? activeBookings[0] : null,
  };
}
