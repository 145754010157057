<template>
  <ValidationProvider v-slot="{ errors, required }" :name="name || label" :rules="rules" slim>
    <div class="SelectInput" :class="{ 'has-errors': !!errors[0] }">
      <div class="flex-grow relative" :class="{ 'pt-5': !!label }">
        <Multiselect
          :id="id"
          ref="multiSelect"
          v-model="innerValue"
          v-bind="$attrs"
          open-direction="bottom"
          :label="labelBy"
          :multiple="multiple"
          :placeholder="placeholder"
          deselect-label="false"
          select-label="false"
          :show-labels="false"
          v-on="$listeners"
        >
          <template #singleLabel="props">
            <slot name="singleLabel" v-bind="props"></slot>
          </template>
          <template #noResult>
            <span class="text-red-100 text-sm font-bold">{{ noResults }}</span>
          </template>
        </Multiselect>

        <label v-if="label" class="SelectInput__label" :for="id" @click="openSelect()">
          {{ label }} {{ required ? '*' : '' }}
        </label>
      </div>

      <span class="mt-1 text-red-100 text-sm absolute">{{ errors[0] }}</span>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
  name: 'MultiSelect',
  components: {
    Multiselect,
    ValidationProvider,
  },
  props: {
    rules: {
      type: [String, Object],
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    value: {
      type: null,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    labelBy: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    noResults: {
      type: String,
      default: 'لا يوجد نتائج ..',
    },
  },
  data: () => ({
    innerValue: '',
  }),
  computed: {
    hasValue() {
      return !!this.innerValue;
    },
  },
  watch: {
    innerValue(value) {
      if (value === this.value) {
        return;
      }
      this.$emit('input', value);
    },
    value(val) {
      this.innerValue = val;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
  methods: {
    openSelect() {
      this.$refs.multiSelect.open();
    },
  },
};
</script>

<style lang="postcss" scoped>
/* purgecss start ignore */
* >>> .multiselect {
  @apply bg-gray-100 rounded-2xl flex flex-wrap gap-1;
  min-height: 50px;
  min-width: 350px;

  &__placeholder {
    @apply text-black font-normal;
    font-size: 14px;
  }
  &__tags {
    @apply w-4/5 flex flex-col border-none pl-0 pr-3 pt-3 text-sm bg-transparent;
    font-size: 16px;
    min-height: auto;

    input {
      @apply w-3/4 font-normal;
      background: transparent;
      font-size: 14px;
      &::placeholder {
        @apply text-black;
      }
    }
  }

  &__tags-wrap {
    @apply flex flex-wrap gap-x-1;
  }

  &__content-wrapper {
    @apply top-full mt-2 w-full;
    min-height: 100px;
  }

  &__option--highlight {
    @apply bg-gray-100 rounded-2xl text-white;
  }

  &__single {
    @apply bg-transparent pt-1;
  }

  &__spinner {
    @apply bg-gray-100 rounded-2xl top-1 before:border-t-red-100 after:border-t-red-100;
  }

  &__tag {
    @apply mr-0 rounded-lg bg-blue-100/20 text-blue-100 text-sm;
    &-icon:after {
      @apply text-blue-100 text-lg;
    }
    &-icon {
      @apply hover:bg-blue-100/20;
    }
  }

  &__option,
  &__option--selected,
  &__element {
    @apply text-black font-normal rounded-none;
  }

  &__option--selected {
    @apply font-bold;
  }

  &__select {
    @apply py-0 flex items-center justify-center top-0 h-full;
    transform: rotate(0);
    &::before {
      @apply relative inline-block top-0 duration-300 transition-transform;
    }
  }

  &--active {
    .multiselect {
      &__select {
        &::before {
          transform: rotate(180deg);
        }
      }
    }
  }
}

label {
  @apply text-black font-bold text-sm top-0;
}

.SelectInput {
  &__label {
    @apply absolute right-0 z-20 flex items-center justify-start pointer-events-none;
    top: 0%;
    transform: translateY(-50%);
    transition: all 0.1s ease;
    will-change: font-size, transform;
  }
}

* >>> .multiselect,
* >>> .multiselect__input,
* >>> .multiselect__single {
  font-size: 14px;
  font-weight: 600;
}
/* purgecss end ignore */
</style>
