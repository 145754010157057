import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Settings from '../views/Settings.vue';
import Users from '../views/Users.vue';
import BranchSettings from '../views/BranchSettings.vue';
import Customers from '../views/Customers.vue';
import Cities from '../views/Cities.vue';
import BranchesByCity from '../views/BranchesByCity.vue';
import Bookings from '../views/Bookings.vue';
import CustomerSearch from '../views/CustomerSearch.vue';
import ServiceSelection from '../views/ServiceSelection.vue';
import ScheduleServices from '../views/ScheduleServices.vue';
import NewCustomer from '../views/NewCustomer.vue';
import CustomerForm from '../views/CustomerForm.vue';
import NewCustomerVehicles from '../views/NewCustomerVehicles.vue';
import CustomerInfo from '../views/CustomerInfo.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import ResetPassword from '../views/ResetPassword.vue';
import UserForm from '../views/UserForm.vue';
import ReservationChooseCustomer from '../views/Reservation/ChooseCustomer.vue';
import ReservationChooseBattery from '../views/Reservation/ChooseProduct.vue';
import Riders from '../views/Riders.vue';
import Vans from '../views/Vans.vue';
import { getUser } from '@/features/auth';
import RidersLayout from '../layouts/RidersLayout.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
  },
  {
    path: '/branches/:id',
    name: 'Branch',
    component: BranchSettings,
    props: true,
  },
  {
    path: '/customers',
    name: 'Customers',
    component: Customers,
  },
  {
    path: '/region/:id',
    name: 'CitiesByRegion',
    component: Cities,
    props: true,
  },
  {
    path: '/city/:id',
    name: 'BranchesByCity',
    component: BranchesByCity,
    props: true,
  },
  {
    path: '/bookings/:storeId/:date?',
    name: 'Bookings',
    component: Bookings,
  },
  {
    path: '/new-booking/choose-customer',
    name: 'CustomerSearch',
    component: CustomerSearch,
  },
  {
    path: '/new-booking/new-customer',
    name: 'NewCustomer',
    component: NewCustomer,
  },
  {
    path: '/new-booking/choose-services',
    name: 'ServiceSelection',
    component: ServiceSelection,
  },
  {
    path: '/new-booking/schedule-services',
    name: 'ScheduleServices',
    component: ScheduleServices,
  },
  {
    path: '/customer/add',
    name: 'CustomerForm',
    component: CustomerForm,
  },
  {
    path: '/new-customer-vehicles',
    name: 'NewCustomerVehicles',
    component: NewCustomerVehicles,
  },
  {
    path: '/customer/edit/:phone',
    name: 'EditCustomer',
    component: CustomerForm,
    props: true,
  },
  {
    path: '/customer/:phone',
    name: 'CustomerInfo',
    component: CustomerInfo,
    props: true,
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/user/add',
    name: 'UserForm',
    component: UserForm,
  },
  {
    path: '/user/edit/:username',
    name: 'EditUser',
    component: UserForm,
    props: true,
  },
  {
    path: '/riders',
    component: RidersLayout,
    children: [
      {
        path: '/',
        name: 'Riders',
        component: Riders,
        props: true,
      },
      {
        path: 'vans',
        name: 'Vans',
        component: Vans,
        props: true,
      },
    ],
  },
  {
    path: '/reservation/choose-customer',
    name: 'ReservationChooseCustomer',
    component: ReservationChooseCustomer,
  },
  {
    path: '/reservation/choose-product',
    name: 'ReservationChooseProduct',
    component: ReservationChooseBattery,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach(async (to, _, next) => {
  const user = await getUser();

  if (!user && to.name !== 'Login' && to.name !== 'ForgotPassword' && to.name !== 'ResetPassword') {
    // redirect the user to the login page
    next('/login');
  }

  if (Boolean(user) && to.name === 'Login') {
    next('/');
  }

  next();
});

export default router;
