import { AssignJsmRiderDocument, RidersCountDocument, RidersDocument, RidersQueryVariables } from '@/graphql/Riders';
import { toNonNullable } from '@/utils/collections';
import { onMounted, Ref, ref, watch } from '@vue/composition-api';
import { Admin, AssignJsmRiderInput, BookedItem, SearchResultPageInfo, StoreBooking } from 'graphql-types.gen';
import { MaybeReactive, useMutation, useQuery } from 'villus';
import { mapCalendarBookingOrder } from '@/features/bookings';
import { VansCountDocument } from '@/graphql/Vans';

export function useRiders(variables?: MaybeReactive<RidersQueryVariables>) {
  const riders: Ref = ref([]);
  const totalCount: Ref<number> = ref(0);
  const pageInfo: Ref<SearchResultPageInfo> = ref({});

  const { data, isFetching, execute, unwatchVariables } = useQuery({
    query: RidersDocument,
    variables,
    cachePolicy: 'network-only',
  });

  unwatchVariables();

  watch(data, value => {
    riders.value = toNonNullable(value?.admins?.items as Admin[])?.map(mapRider);
    totalCount.value = value?.admins?.total_count as number;
    pageInfo.value = value?.admins?.page_info as SearchResultPageInfo;
  });

  return {
    riders,
    totalCount,
    pageInfo,
    isFetchingRiders: isFetching,
    fetchRiders: execute,
  };
}

function mapRider(apiRider: Admin) {
  return {
    fullName: `${apiRider.firstname} ${apiRider.lastname}`,
    completedRequestsNo: apiRider?.completed_jsm_requests?.total_count,
    branches: apiRider?.store_locators?.items,
    status: apiRider?.active_jsm_request?.status?.toLowerCase() || '',
    ...(apiRider?.active_jsm_request?.booking && {
      booking: mapActiveBookingOrder(apiRider?.active_jsm_request?.booking),
    }),
  };
}

// On map active booking order
export function mapActiveBookingOrder(apiOrder: StoreBooking) {
  const orderBooking = toNonNullable(apiOrder?.items?.filter(service => service?.booking) as Partial<BookedItem>[])
    .map(booking => mapCalendarBookingOrder(booking, apiOrder))
    .flat();

  return orderBooking?.length ? orderBooking[0] : null;
}

export function useAssginRider() {
  const { execute, isFetching } = useMutation(AssignJsmRiderDocument);

  async function assgin(input: AssignJsmRiderInput) {
    try {
      const { data, error } = await execute({ input });

      if (error) {
        throw new Error(error.message);
      }

      return data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      throw err;
    }
  }

  return { assgin, isFetching };
}

/**
 * Composable function to fetch and provide the total count of riders and vans.
 *
 * Uses GraphQL queries to fetch the total counts of riders and vans, storing the results
 * in reactive references. Queries are executed when the component is mounted.
 *
 * @returns {Object} An object containing:
 * - `ridersTotalCount` (Ref<number>): The total number of riders.
 * - `vansTotalCount` (Ref<number>): The total number of vans.
 */
export const useRidersTotalCount = () => {
  // Reactive references to hold total counts
  const ridersTotalCount = ref(0);
  const vansTotalCount = ref(0);

  // GraphQL queries for riders and vans total counts

  const { execute: executeRiders } = useQuery({
    query: RidersCountDocument,
    cachePolicy: 'network-only',
    fetchOnMount: false,
  });

  const { execute: executeVans } = useQuery({
    query: VansCountDocument,
    cachePolicy: 'network-only',
    fetchOnMount: false,
  });

  onMounted(async () => {
    try {
      const [riders, vans] = await Promise.all([executeRiders(), executeVans()]);
      ridersTotalCount.value = riders?.data?.admins?.total_count || 0;
      vansTotalCount.value = vans?.data?.admins?.total_count || 0;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  });

  return {
    ridersTotalCount,
    vansTotalCount,
  };
};
