import { OrderByNumberDocument } from '@/graphql/Orders';
import { toNonNullable } from '@/utils/collections';
import { CustomerOrder } from 'graphql-types.gen';
import { useQuery } from 'villus';

export type OrderType = ReturnType<typeof mapOrder>;

export function useOrderByNumber() {
  const { execute, isFetching } = useQuery({
    query: OrderByNumberDocument,
    cachePolicy: 'network-only',
  });

  async function getOrderByNumber(orderNumber: string) {
    try {
      const { data, error } = await execute({ variables: { orderNumber } });
      if (error) {
        throw new Error(error.message);
      }

      return mapOrder(data?.order as CustomerOrder);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      throw err;
    }
  }

  return {
    getOrderByNumber,
    isFetchingOrder: isFetching,
  };
}

export type OrderItem = {
  id: string | number | undefined;
  sku: string | undefined;
  name: string;
  quantity: number | undefined;
  bookableServiceUid: string | undefined;
  storeSlotDuration: number | undefined;
  oldPrice: number;
  newPrice: number;
  discounted: boolean;
};

function mapOrder(order: CustomerOrder) {
  const mappedOrder = {
    orderNumber: order?.number,
    isJumpstart: order?.is_jump_start_order,
    isVan: order?.is_van_service_order,
    jumpstartStore: {
      id: order?.jump_start_me?.store_locator?.id,
    },
    vanStore: {
      id: order?.van_service_info?.store_locator?.id,
    },
    subtotal: order.total?.subtotal.value,
    discount: order.total?.discounts?.[0],
    total: order.total?.grand_total.value,
    batteryImage: mapBatteryImage(order),
    rider: mapRider(order),
    outstandingAmount: mapOutstandingAmount(order),
    paidAmount: mapPaidAmount(order),
    location: mapLocation(order),
    acceptPaymentId: order?.acceptPaymentId,
    status: mapStatus(order),
    paymentMethod: order?.payment_methods?.length ? order?.payment_methods[0]?.name : '',
    items: toNonNullable(order.items).map(item => {
      return {
        id: item?.id,
        sku: item?.product_sku,
        name: !item?.booked_service ? `+ ${item?.product_name}` : item?.product_name,
        quantity: item?.quantity_ordered,
        bookableServiceUid: item?.booked_service?.bookable_service_uid,
        storeSlotDuration: item?.booked_service?.store_duration_slots,
        oldPrice: item?.product_original_price.value,
        newPrice: item?.product_sale_price.value,
        discounted: item?.product_sale_price.value !== item?.product_original_price.value,
      };
    }),
  };

  // To keep add ons at the end of the order details
  // We identify add ons by undefined bookable service data, as they are not scheduled
  const services = mappedOrder.items?.filter(item => item.bookableServiceUid);
  const addOns = mappedOrder.items?.filter(item => !item.bookableServiceUid);

  mappedOrder.items = services?.concat(addOns);

  return mappedOrder;
}

/**
 * Maps a CustomerOrder to return the rider information based on the type of order.
 *
 * @param {CustomerOrder} order - The customer order object containing order details.
 * @returns {string} The rider information, or an empty string if no rider is found.
 */
function mapRider(order: CustomerOrder) {
  if (order?.is_jump_start_order) {
    return order?.jump_start_me?.rider;
  }
  if (order?.is_van_service_order) {
    return order?.van_service_info?.rider;
  }
  return '';
}
/**
 * Maps a CustomerOrder to return the outstanding amount based on the type of order.
 *
 * @param {CustomerOrder} order - The customer order object containing order details.
 * @returns {string} The outstanding amount, or an empty string if no outstanding amount is found.
 */
function mapOutstandingAmount(order: CustomerOrder) {
  if (order?.is_jump_start_order) {
    return order?.jump_start_me?.outstanding_amount;
  }
  if (order?.is_van_service_order) {
    return order?.van_service_info?.outstanding_amount;
  }
  return '';
}
/**
 * Maps a CustomerOrder to return the paid amount based on the type of order.
 *
 * @param {CustomerOrder} order - The customer order object containing order details.
 * @returns {string} The paid amount, or an empty string if no paid amount is found.
 */
function mapPaidAmount(order: CustomerOrder) {
  if (order?.is_jump_start_order) {
    return order?.jump_start_me?.paid_amount;
  }
  if (order?.is_van_service_order) {
    return order?.van_service_info?.paid_amount;
  }
  return '';
}

/**
 * Maps the status of an order based on its type.
 *
 * This function checks if the order is a jumpstart or van service order and returns the status
 * in lowercase. If the order type is not identified, it returns an empty string.
 *
 * @param {CustomerOrder} order - The order object to map the status from.
 * @returns {string} The status of the order in lowercase, or an empty string if the status is not available.
 */
function mapStatus(order: CustomerOrder) {
  if (order?.is_jump_start_order) {
    return order?.jump_start_me?.status?.toLowerCase();
  }
  if (order?.is_van_service_order) {
    return order?.van_service_info?.status?.toLowerCase();
  }
  return '';
}

/**
 * Maps the battery image URL of an order based on its type.
 *
 * This function checks if the order is a jumpstart or van service order and returns the URL of the battery image.
 * If the order type is not identified, it returns an empty string.
 *
 * @param {CustomerOrder} order - The order object to map the battery image from.
 * @returns {string} The URL of the battery image, or an empty string if the image URL is not available.
 */
function mapBatteryImage(order: CustomerOrder) {
  if (order?.is_jump_start_order) {
    return order?.jump_start_me?.battery_image;
  }
  if (order?.is_van_service_order) {
    return order?.van_service_info?.battery_image;
  }
  return '';
}

/**
 * Maps the location of an order based on its type.
 */
function mapLocation(order: CustomerOrder) {
  if (order?.is_jump_start_order) {
    return order?.jump_start_me?.location;
  }
  if (order?.is_van_service_order) {
    return order?.van_service_info?.location;
  }
  return '';
}
