import mitt from 'mitt';
import type { Handler } from 'mitt';
import { onUnmounted, onMounted } from '@vue/composition-api';

let emitter!: ReturnType<typeof mitt>;

type AppEvent = 'BOOKINGS_UPDATED' | 'RESERVATION_UPDATED';

export function useEventBus() {
  if (!emitter) {
    emitter = mitt();
  }

  function emit(evt: AppEvent, data?: any) {
    if (!emitter) return;

    return emitter.emit(evt, data);
  }

  function on(evt: AppEvent, cb: Handler<any>) {
    if (!emitter) return;

    return emitter.on(evt, cb);
  }

  function off(evt: AppEvent, cb: Handler<any>) {
    if (!emitter) return;

    return emitter.off(evt, cb);
  }

  return {
    emit,
    on,
    off,
  };
}

export function useEvent(evt: AppEvent, callback: Handler<any>) {
  const { on, off } = useEventBus();

  onMounted(() => {
    on(evt, callback);
  });

  onUnmounted(() => {
    off(evt, callback);
  });
}
