import { ref } from '@vue/composition-api';
import * as XLSX from 'xlsx';
import { CalendarBookingOrder } from '@/features/bookings';
import { zipObject, isEqual } from 'lodash-es';

/**
 * Custom hook for exporting JSON data to an Excel file.
 * @returns {Object} An object containing the export function and the loading state.
 */
export const useExportExcel = () => {
  const loading = ref(false);

  /**
   * Exports the provided JSON data to an Excel file.
   * @param {any[]} items - The JSON data to be exported.
   * @param {string} [name='file'] - The base name for the exported Excel file. A timestamp will be appended to this name.
   * @returns {Promise<void>} A promise that resolves when the export is complete.
   */
  const mutate = async (items: CalendarBookingOrder[], name: string = 'file') => {
    try {
      loading.value = true;

      const currentName = `${name}-${Date.now()}`;

      // Mapped item
      const mappedItems = items?.map(mapExcelItem);

      const worksheet = XLSX.utils.json_to_sheet(mappedItems);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      await XLSX.writeFile(workbook, `${currentName}.xlsx`);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    } finally {
      loading.value = false;
    }
  };

  return {
    mutate,
    loading,
  };
};

/**
 * Maps a CalendarBookingOrder item to a format suitable for exporting to Excel.
 * @param {CalendarBookingOrder} item - The item to be mapped.
 * @returns {Record<string, any>} The mapped item with keys formatted for Excel export.
 */
function mapExcelItem(item: CalendarBookingOrder) {
  return {
    'Rider Name': item?.jumpstartInfo?.rider?.fullName || 'N/A',
    'Creation Date': item?.start || 'N/A',
    'Order ID': item?.orderNumber || 'N/A',
    'Customer Name': item?.customer?.name || 'N/A',
    'Mobile Number': item?.customer?.telephone || 'N/A',
    Sku: item?.sku || 'N/A',
    Service: item?.name || 'N/A',
    'Product Name': item?.products?.length ? mapProductsName(item?.products) : 'N/A',
    'Order Status': item?.status?.toUpperCase() || 'N/A',
    'Cancellation Reason': item?.cancellationReason || 'N/A',
    'Order Total': item?.price || 0,
  };
}

/**
 * Maps the product names from an array of products and joins them into a single string.
 *
 * @param {Array<Record<string, string>>} products - The array of products, each represented as a record of key-value pairs.
 * @returns {string} - A comma-separated string of product names.
 */
function mapProductsName(products: Record<string, string>[]) {
  return products?.map(product => product?.name).join(',');
}

/**
 * Defines the structure of an object representing a row in the service Excel data.
 * This type represents an object where each key corresponds to a header and each value is either a string or a number.
 */
export type ExcelServiceObject = ReturnType<typeof mapJsonRow>;

/**
 * Custom hook to process an Excel file and convert its content to JSON format.
 *
 * This function reads an Excel file, validates its headers, and converts the data into JSON objects.
 *
 * @returns {object} An object containing the `mutate` function to process the file.
 */
export const useExcelToJson = () => {
  // Define the required headers
  const requiredHeaders = [
    'sku',
    'store_capacity',
    'is_enabled_in_store',
    'store_duration_slots',
    'lead_time',
    'working_from',
    'working_to',
  ];

  const mutate = (file: File) => {
    if (!file) return;
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();

        reader.onload = function (event) {
          const result = event?.target?.result;
          if (!(result instanceof ArrayBuffer)) {
            return reject(new Error('ملف غير صالح'));
          }

          const data = new Uint8Array(result);
          const workbook = XLSX.read(data, { type: 'array' });

          // Assuming the first sheet is the one you want
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];

          // Convert sheet to JSON
          const rawJson = XLSX.utils.sheet_to_json<(string | number)[]>(sheet, { header: 1 });
          if (!rawJson.length) {
            resolve([]);
          }

          const [headers, ...rows] = rawJson;

          // Validate headers
          if (!isEqual(headers, requiredHeaders)) {
            reject(new Error('تنسيق الملف غير صالح'));
          }

          // Map json rows
          const mappedRows = rows?.map(mapJsonRow(headers));
          resolve(mappedRows);
        };

        reader.readAsArrayBuffer(file);
      } catch (err: any) {
        // eslint-disable-next-line no-console
        console.log(err);
        reject(new Error(err));
      }
    });
  };

  return {
    mutate,
  };
};

/**
 * Maps a row of values to an object using the provided headers.
 */
function mapJsonRow(headers: (string | number)[]) {
  return (row: (string | number)[]) => {
    return zipObject(headers, row);
  };
}
