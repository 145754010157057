/**
 * Represents a key for comparing with a unique string.
 * @type {string}
 * @readonly
 * @constant
 */
export const JUMPSTART = 'jumpstart';

/**
 * Represents the key used for reservations.
 * @constant {string}
 */
export const RESERVATION = 'reservation';
